import React from 'react'
import { Text as RNText } from 'react-native'
import type { TextProps } from './text.web.props'
import { useStyles } from './text.web.styles'

export const Text = (props: TextProps) => {
  const { accessibilityLabel, children, numberOfLines, testID, ellipsizeMode, onPress } = props
  const styles = useStyles(props)

  return (
    <RNText
      accessibilityLabel={accessibilityLabel}
      ellipsizeMode={ellipsizeMode}
      numberOfLines={numberOfLines}
      onPress={onPress}
      style={styles.text}
      testID={testID}
    >
      {children}
    </RNText>
  )
}
