/* eslint typescript-sort-keys/string-enum: "error" */
export enum TextDescriptionVariant {
  JUMBO_PRIMARY_BOLD = 'Primary Bold jumbo text',
  JUMBO_PRIMARY_REGULAR = 'Primary Regular jumbo text',
  JUMBO_PRIMARY_XBOLD = 'Primary extra Bold jumbo text',
  JUMBO_SECONDARY_BOLD = 'Secondary Bold Jumbo text',
  JUMBO_SECONDARY_SEMIBOLD = 'Secondary Semi Bold Jumbo text',

  LARGE_ITALIC_SECONDARY_SEMIBOLD = 'Secondary Italic Semi Bold Large text',
  LARGE_PRIMARY_BOLD = 'Primary Bold large text',
  LARGE_PRIMARY_REGULAR = 'Primary Regular large text',
  LARGE_PRIMARY_XBOLD = 'Primary extra Bold large text',
  LARGE_SECONDARY_BOLD = 'Secondary Bold Large text',
  LARGE_SECONDARY_SEMIBOLD = 'Secondary Semi Bold Large text',

  MEDIUM_ITALIC_SECONDARY_SEMIBOLD = 'Secondary Italic Semi Bold Medium text',
  MEDIUM_PRIMARY_BOLD = 'Primary Bold medium text',
  MEDIUM_PRIMARY_LIGHT = 'Primary Light medium text',
  MEDIUM_PRIMARY_MEDIUM = 'Primary Medium medium text',
  MEDIUM_PRIMARY_REGULAR = 'Primary Regular medium text',
  MEDIUM_PRIMARY_SEMIBOLD = 'Primary Semi Bold medium text',
  MEDIUM_PRIMARY_XBOLD = 'Primary extra Bold medium text',
  MEDIUM_SECONDARY_BOLD = 'Secondary Bold Medium text',
  MEDIUM_SECONDARY_SEMIBOLD = 'Secondary Semi Bold Medium text',

  REGULAR_ITALIC_SECONDARY_BOLD = 'Secondary Italic Bold Regular text',
  REGULAR_PRIMARY_BOLD = 'Primary Bold regular text',
  REGULAR_PRIMARY_LIGHT = 'Primary Light regular text',
  REGULAR_PRIMARY_MEDIUM = 'Primary Medium regular text',
  REGULAR_PRIMARY_REGULAR = 'Primary Regular regular text',
  REGULAR_PRIMARY_XBOLD = 'Primary extra Bold regular text',
  REGULAR_SECONDARY_MEDIUM = 'Secondary Medium Regular text',
  REGULAR_SECONDARY_SEMIBOLD = 'Secondary Semi Bold Regular text',

  SMALL_ITALIC_PRIMARY_REGULAR = 'Primary Italic Regular Small text',
  SMALL_ITALIC_SECONDARY_MEDIUM = 'Secondary Italic Medium Small text',
  SMALL_ITALIC_SECONDARY_SEMIBOLD = 'Secondary Italic Semi Bold Small text',
  SMALL_PRIMARY_BOLD = 'Primary Bold small text',
  SMALL_PRIMARY_MEDIUM = 'Primary Medium small text',
  SMALL_PRIMARY_REGULAR = 'Primary Regular small text',
  SMALL_PRIMARY_XBOLD = 'Primary extra Bold small text',
  SMALL_SECONDARY_MEDIUM = 'Secondary Medium Small text',
  SMALL_SECONDARY_REGULAR = 'Secondary Regular Small text',
  SMALL_SECONDARY_SEMIBOLD = 'Secondary Semi Bold Small text',

  XJUMBO_SECONDARY_BOLD = 'Extra Jumbo Secondaty Bold Text',
  XLARGE_ITALIC_SECONDARY_BOLD = 'Secondary Italic Bold extra Large text',
  XLARGE_PRIMARY_BOLD = 'Primary Bold extra large text',
  XLARGE_PRIMARY_REGULAR = 'Primary Regular extra large text',
  XLARGE_SECONDARY_BOLD = 'Secondary Bold extra Large text',
  XMEDIUM_ITALIC_PRIMARY_BOLD = 'Primary Italic Bold extra medium text',
  XMEDIUM_ITALIC_PRIMARY_REGULAR = 'Primary Italic Regular extra medium text',
  XMEDIUM_PRIMARY_BOLD = 'Primary Bold extra medium text',
  XMEDIUM_PRIMARY_REGULAR = 'Primary Regular extra medium text',
  XSMALL_ITALIC_PRIMARY_BOLD = 'Primary Italic extra Bold extra Small text',
  XSMALL_ITALIC_SECONDARY_SEMIBOLD = 'Secondary Italic Semi Bold extra Small text',
  XSMALL_ITALIC_SECONDARY_XBOLD = 'Secondary Italic extra Bold extra Small text',
  XSMALL_PRIMARY_BOLD = 'Primary Bold extra small text',
  XSMALL_PRIMARY_MEDIUM = 'Primary Medium extra small text',
  XSMALL_PRIMARY_REGULAR = 'Primary Regular extra small text',
  XSMALL_PRIMARY_XBOLD = 'Primary extra Bold extra small text',
  XSMALL_SECONDARY_BOLD = 'Secondary Bold extra Small text',
  XSMALL_SECONDARY_MEDIUM = 'Secondary Medium extra Small text',
  XSMALL_SECONDARY_REGULAR = 'Secondary Regular extra Small text',
  XSMALL_SECONDARY_SEMIBOLD = 'Secondary Semi Bold extra Small text',

  XXLARGE_PRIMARY_BOLD = 'Primary Bold very extra large text',
  XXLARGE_PRIMARY_XBOLD = 'Primary extra Bold very extra large text',
  XXLARGE_SECONDARY_BOLD = 'Secondary Bold very extra Large text',
  XXSMALL_ITALIC_PRIMARY_BOLD = 'Primary Italic Bold very extra small text',
  XXSMALL_PRIMARY_BOLD = 'Primary Bold very extra small text',
}
